* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth
}
body {
  overflow: hidden;
}
.recharts-layer {
  outline: none;
}
.custom-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}
